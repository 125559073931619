import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'isFirstPage',
    // redirect: '/firstpage',
    component: () => import(/* webpackChunkName: "about" */ '../views/FirstPage.vue')
  },
  {
    path: '/firstpage',
    name: 'FirstPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/FirstPage.vue')
  },
  {
    path: '/hotdry',
    name: 'HotDry',
    component: () => import(/* webpackChunkName: "about" */ '../views/HotDry.vue')
  },
  {
    path: '/ishelp',
    name: 'IsHelp',
    component: () => import(/* webpackChunkName: "about" */ '../views/IsHelp.vue')
  },
  {
    path: '/ismore',
    name: 'IsMore',
    component: () => import(/* webpackChunkName: "about" */ '../views/IsMore.vue')
  },
  {
    path: '/isAbout',
    name: 'isAbout',
    component: () => import(/* webpackChunkName: "about" */ '../views/isAbout.vue')
  },
  {
    path: '/lxUs',
    name: 'lxUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/LxUs.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
