export default {
    install(Vue) {
        Vue.mixin({
            data(){
                return {
                    clickBtuuonRepeat: false,
                    isShowArr: ['fyswxx.cn'],
                    isShowArrData:[
                        {url:'fyswxx.cn',lxr:'方老师',phone:'13262222601',site:'江苏省南京市江北新区葛塘街道宁六路349号3026-10室',name:'阜阳时蔚信息科技有限公司南京分公司', icp:'皖ICP备2024068126号-2'},
                    ],
                    footerCompany: ['fyswxx.cn'],
                    matchHostArr : [
                        { host: 'fyswxx.cn', name: '阜阳时蔚信息科技有限公司南京分公司' },
                      ],
                }
            },
            methods:{

                //将url转成对象
                parseQueryString(url) {
                    //url参数转对象
                    url = !url ? window.location.href : url
                    if (url.indexOf('?') === -1) {
                    return null
                    }
                    let search = url[0] === '?' ? url.substr(1) : url.substring(url.lastIndexOf('?') + 1)
                    if (search === '') {
                    return null
                    }
                    search = search.split('&')
                    let query = {}
                    for (let i = 0; i < search.length; i++) {
                    let pair = search[i].split('=')
                    // query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
                    query[pair[0]] = pair[1] || ''
                    }
                    return query
                },

                isToPdf(){

                }
            }
        })
    }
}